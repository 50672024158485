import {useEffect, useRef, useState} from "react";
import {HomeState} from "../Interfaces/HomeState";
import {HomeController} from "../controllers/HomeController";
import Loading from "./component/Loading";
import Header from "./sections/Header";
import Main from "./sections/Main";
import {get} from "lodash";
import Footer from "./sections/Footer";

export default function Home() {
    // Create controller only once
    const homeControllerRef = useRef<HomeController | null>(null);
    const [state, setState] = useState<HomeState>({
        is_play: false,
        loading4play: true,
        first_play: false,
        loading: true,
        background: "",
        channels: []
    });

    useEffect(() => {
        if (!homeControllerRef.current) {
            homeControllerRef.current = new HomeController(state, setState);
            homeControllerRef.current.init().then();
            // Register Re Render
            homeControllerRef.current.registerRerender();
        }

        return () => {
            return homeControllerRef.current?.uninstallRerender();
        }
    }, [state]);

    if (state.loading) return <Loading/>;

    return (
        <div className={"container"} style={{backgroundImage: `url("${get(state, "channel.image")}")`}}>
            <div className={"dark"}/>
            <Header/>
            <Main state={state}/>
            <Footer state={state} homeController={homeControllerRef.current}/>
        </div>
    )
}