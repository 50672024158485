import {createBrowserRouter} from "react-router-dom";
import Home from "./features/home/views/Home";
import Unlock from "./features/home/views/Unlock";

export default createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/bfc1fa2c10e1700eaf4cd8aa859e5b028c4c32cb490bdde2c1a653d5d33eb06d",
        element: <Unlock/>,
    },
]);