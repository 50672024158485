import {ReactComponent as Logo} from "../../../../assets/images/logo.svg"
import {ReactComponent as Report} from "../../../../assets/images/report.svg"
import {ReactComponent as Android} from "../../../../assets/images/android.svg"

export default function Header() {
    return (
        <header>
            <h1>
                <a href="https://music24.me">
                    <Logo/>
                </a>
            </h1>
            <div>
                {/*<div>
                    <img referrerPolicy='origin' id='rgvjjzpenbqejzpefukzesgt'
                         style={{cursor: "pointer", width: "auto", height: "24px"}}
                         onClick={() => {
                             window.open("https://logo.samandehi.ir/Verify.aspx?id=372760&p=xlaojyoeuiwkjyoegvkaobpd", "Popup", "toolbar=no, scrollbars=no, location=no, statusbar=no, menubar=no, resizable=0, width=450, height=630, top=30")
                         }}
                         alt='logo-samandehi'
                         src='https://logo.samandehi.ir/logo.aspx?id=372760&p=qftiyndtodrfyndtwlbqlyma'/>
                </div>*/}
                <div>
                    <a href="https://music24.me/app-release.apk" download={"music24.apk"}>
                        <Android/>
                    </a>
                </div>
                <div>
                    <a href="https://music24.me#report">
                        <Report/>
                    </a>
                </div>
            </div>
        </header>
    )
}