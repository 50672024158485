import {get, keys} from "lodash";

export default class EventListenerRepository {
    list: {}

    constructor() {
        this.list = {
            // Request get user profile
            // "checkLogin": LoginController.CheckLogin.bind(this),
            // "titleUpdate": PanelController.TitleUpdate.bind(this),
            // "profileCheck": CategoryController.profileCheck.bind(this),
            // "panelInit": PanelController.panelInit.bind(this)
        }
    }

    register() {
        keys(this.list).map(key => {
            return window.addEventListener(key, get(this.list, key), false)
        })
    }

    uninstall() {
        keys(this.list).map(key => {
            return window.removeEventListener(key, get(this.list, key), false)
        })
    }
}