import {useEffect} from "react";
import HomeRepository from "../repository/HomeRepository";
import {useNavigate} from "react-router-dom";

export default function Unlock() {
    const navigate = useNavigate();

    useEffect(() => {
        HomeRepository.setUnlock();

        navigate(`/`, {replace: true});
    })


    return <></>;
}