import RequestHandler from "../../../services/RequestHandler";
import {StatsInterface} from "../Interfaces/StatsInterface";
import Channels from "../../../assets/data/channels.json"
import LocalStorageRepository from "../../../repository/LocalStorageRepository";

export default class HomeRepository {
    static readonly ulc = "bfc1fa2c10e1700eaf4cd8aa859e5b028c4c32cb490bdde2c1a653d5d33eb06d";

    async getChannels() {
        return Channels;
    }

    async stats() {
        return await RequestHandler.get("https://st1.music24.me/stats/") as StatsInterface
    }

    static setUnlock() {
        LocalStorageRepository.set("unlock_channels", this.ulc)
    }
}