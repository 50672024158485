import Lottie from "react-lottie";
import wave from "../../../../assets/images/wave.json";

export default function Wave({height, width, isPaused = false}: { height: number, width: number, isPaused?: boolean }) {
    return (<Lottie
        isClickToPauseDisabled={true} height={height} width={width}
        isPaused={isPaused}
        options={{
            loop: true,
            autoplay: true,
            animationData: wave,
            rendererSettings: {preserveAspectRatio: "xMidYMid slice"}
        }}
    />);
}