import {HomeState} from "../../Interfaces/HomeState";
import {ReactComponent as UserOnline} from "../../../../assets/images/userOnline.svg"
import Wave from "../component/Wave";
import {get, has} from "lodash";

export default function Main({state}: { state: HomeState }) {
    const channelName = get(state, "channel.name", "-") as string
    const channelNamePath = channelName.replaceAll(" ", "-").toLowerCase()
    const user_online = get(state, `user_online.paths["/hls/${channelNamePath}"]`, "0");

    return (
        <main>
            {state.is_play ? <Wave width={208} height={80}/> : (<div style={{minHeight: 80}}/>)}
            <div>
                <h2>{channelName}</h2>
            </div>
            {
                has(state, "user_online") ?
                    <div className={"user-online"}>
                        <UserOnline/>
                        <span>{parseInt(user_online) + 1}</span>
                    </div>
                    : null
            }
        </main>
    );
}