import {get, isEmpty} from "lodash";
import {ReactComponent as Play} from "../../../../assets/images/play.svg";
import {ReactComponent as Pause} from "../../../../assets/images/pause.svg";
import Wave from "../component/Wave";
import {HomeController} from "../../controllers/HomeController";
import {HomeState} from "../../Interfaces/HomeState";
import {useEffect, useRef} from "react";
import Hls from 'hls.js';
import Loading4play from "../component/Loading4play";

export default function Footer({state, homeController}: {
    state: HomeState,
    homeController: HomeController | null
}) {
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const link = get(state, "channel.link", "")
    useEffect(() => {
        if (audioRef.current) {
            homeController?.setAudioRef(audioRef)
            if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(link);
                hls.attachMedia(audioRef.current);
            } else if (audioRef.current.canPlayType('application/vnd.apple.mpegurl')) {
                audioRef.current.src = link;
            } else {
                window.alert("Hls is not supported!");
            }
        }
    }, [link]);

    return (<section className="footer">
        <ul>
            {
                state.channels.length > 0 ?
                    state.channels.map(item => {
                        const {name, image} = item;
                        const is_active = name.toLowerCase() === get(state, "channel.name", "").toLowerCase();
                        const is_play = get(state, "is_play", false);
                        return (
                            <li key={`channel-${name}`}
                                onClick={() => homeController?.active(item)}
                                className={is_active ? "active" : undefined}>
                                <div>
                                    {is_active && is_play ? <Wave width={60} height={23}/> : null}
                                    <img src={image} alt={name}/>
                                </div>
                                <h3>{name}</h3>
                            </li>
                        )
                    })
                    : null
            }
        </ul>
        <div>
            {state.loading4play ?
                <Loading4play/> :
                state.is_play ?
                    <Pause onClick={() => homeController?.pause()}/> :
                    <Play onClick={() => homeController?.play()}/>}
            <audio
                onCanPlay={() => homeController?.loading4playOff()}
                ref={audioRef} style={{display: 'none'}} autoPlay={state.is_play} controls={false}
                crossOrigin="anonymous" playsInline={true} webkit-playsinline="true"
            />
        </div>
    </section>);
}