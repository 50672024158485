import {isNull, isString} from "lodash";

export default class LocalStorageRepository {
    static set(key: string, value: any) {
        const data = isString(value) ? value : JSON.stringify(value)
        localStorage.setItem(key, data)

        // For Rerender
        setTimeout(() => window.dispatchEvent(new Event('reRender')), 500)
    }

    static get(key: string, defaultData: null | string = null) {

        const data = localStorage.getItem(key)

        if (isNull(key))
            return defaultData

        return data
    }

    static remove(key: string) {
        localStorage.removeItem(key)

        // For Rerender
        setTimeout(() => window.dispatchEvent(new Event('reRender')), 500)
    }
}