import {ChannelsState, HomeState} from "../Interfaces/HomeState";
import React from "react";
import {first, random} from "lodash";
import HomeRepository from "../repository/HomeRepository";
import EventListenerRepository from "../../../repository/System/EventListenerRepository";
import LocalStorageRepository from "../../../repository/LocalStorageRepository";
import IsPWA from "../../../utility/IsPWA";
import IsIOS from "../../../utility/IsIOS";

export class HomeController {
    protected state: HomeState;
    protected setState: React.Dispatch<React.SetStateAction<HomeState>>;
    protected repository: HomeRepository;
    protected readonly channelsIgnore = ["Mixed", "Pop", "Rock", "Arabic", "Car", "Dance", "Dj Mix", "Turkish", "World Pop"];

    constructor(state: HomeState, setState: React.Dispatch<React.SetStateAction<HomeState>>) {
        this.state = state;
        this.setState = setState;
        this.repository = new HomeRepository();
    }

    // Initialize the controller
    async init() {
        const channels = await this.getChannelsList();
        const last_active = LocalStorageRepository.get("last_active");
        this.setUserOnline();
        this.updateStateAfterLoading(channels, last_active);
    }

    // Play the audio
    play() {
        this.saveState({is_play: true, first_play: true});
        this.state.audioRef?.current?.play();
    }

    // Pause the audio
    pause() {
        this.saveState({is_play: false});
        this.state.audioRef?.current?.pause();
    }

    // Get and filter the channels list
    async getChannelsList() {
        const unlock_channels = LocalStorageRepository.get("unlock_channels");
        return (await this.repository.getChannels()).filter((item) => {
            if (unlock_channels === HomeRepository.ulc) return true;
            return this.isIosPwa() || !this.channelsIgnore.includes(item.name);
        });
    }

    // Set user online status
    setUserOnline() {
        this.repository.stats().then((result) => this.saveState({user_online: result}));
    }

    // Update state after loading process
    updateStateAfterLoading(channels: ChannelsState[], last_active: string | null) {
        setTimeout(() => {
            this.saveState({
                loading: false,
                channel: last_active ? first(channels.filter((item) => last_active === item.name)) : first(channels),
                channels: channels,
            });
        }, 1500);
    }

    // Set audio ref
    setAudioRef(audioRef: React.MutableRefObject<HTMLAudioElement | null>) {
        this.saveState({audioRef: audioRef});
    }

    // Activate a channel
    active(item: ChannelsState) {
        this.setUserOnline();
        if (item.name !== this.state.channel?.name) {
            this.saveState({channel: item, loading4play: true, first_play: true});
        }
        LocalStorageRepository.set("last_active", item.name);
    }

    // Handle loading after play
    loading4playOff() {
        this.saveState({loading4play: false});
        if (this.state.first_play) {
            this.play();
        }
    }

    // Check if the app is running on iOS as a PWA
    isIosPwa() {
        return IsPWA() && IsIOS();
    }

    // Re-render the app (with a random number)
    reRender() {
        this.saveState({reRender: random(999999999)});
    }

    // Register event listeners for re-rendering
    registerRerender() {
        new EventListenerRepository().register();
        window.addEventListener("reRender", this.reRender.bind(this), false);
    }

    // Unregister event listeners for re-rendering
    uninstallRerender() {
        new EventListenerRepository().uninstall();
        window.removeEventListener("reRender", this.reRender.bind(this), false);
    }

    // Save state helper
    saveState(newState: Partial<HomeState>) {
        const updatedState = {...this.state, ...newState};
        this.state = updatedState;
        this.setState?.(updatedState);
    }
}
